import React, { useState } from 'react'; // Import useState
import './EWarehouse.css'; // Ensure the CSS file reflects the updated class names
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faChartLine, faSyncAlt, faLock, faWarehouse, faCog, faQuestionCircle, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

const EWarehouse = () => {
  const [showInstructions, setShowInstructions] = useState(false); // Declare state for toggle
  const navigate = useNavigate(); // Initialize the navigate hook for navigation

  const toggleInstructions = () => {
    setShowInstructions(!showInstructions); // Toggle the state when clicked
  };
  const handleNavigateToContact = () => {
    navigate("/contact"); // This will navigate to the contact page
  };

  return (
    <div className="e-warehouse-container">
      <h3 className="e-warehouse-title">E-warehouse</h3>
      <div className="e-warehouse-content">
        <div className="e-warehouse-card">
          <FontAwesomeIcon icon={faClipboardCheck} className="e-warehouse-icon" />
          <p className="e-warehouse-text">
            Our E-warehouse solution is designed to revolutionize how you handle your supply chain management, bringing 
            speed, accuracy, and transparency to every step of the process. This platform offers a seamless integration 
            of inventory management, real-time order fulfillment, and advanced analytics to give you full control 
            and visibility over your warehouse operations.
          </p>
        </div>
        <div className="e-warehouse-card">
          <FontAwesomeIcon icon={faWarehouse} className="e-warehouse-icon" />
          <p className="e-warehouse-text">
            With E-warehouse, you can manage inventory across multiple locations, track stock levels in real time, 
            and automatically replenish items to ensure that you're never out of stock. The system integrates with 
            existing enterprise resource planning (ERP) systems and allows you to manage all your warehouses from a 
            single dashboard, simplifying operations for both small and large businesses.
          </p>
        </div>
        <div className="e-warehouse-card">
          <FontAwesomeIcon icon={faCog} className="e-warehouse-icon" />
          <p className="e-warehouse-text">
            Our powerful automation features are designed to reduce human error and streamline order fulfillment, 
            reducing manual tasks such as picking, packing, and shipping. By automating these processes, you can 
            significantly increase productivity, reduce overhead costs, and improve order accuracy, all while maintaining 
            customer satisfaction.
          </p>
        </div>
        <div className="e-warehouse-card">
          <FontAwesomeIcon icon={faSyncAlt} className="e-warehouse-icon" />
          <p className="e-warehouse-text">
            E-warehouse is built with scalability in mind, making it suitable for businesses of all sizes. Whether 
            you're running a small business or managing a large, complex distribution network, the platform adapts to 
            your specific needs. As your business grows, E-warehouse grows with you, allowing you to easily add new 
            locations, users, and functionality without any disruptions.
          </p>
        </div>
        <div className="e-warehouse-card">
          <FontAwesomeIcon icon={faChartLine} className="e-warehouse-icon" />
          <p className="e-warehouse-text">
            The intuitive dashboard provides comprehensive, real-time insights into key performance indicators (KPIs) 
            such as order fulfillment rates, inventory turnover, shipping costs, and more. With these analytics at 
            your fingertips, you can make data-driven decisions to optimize warehouse operations, reduce inefficiencies, 
            and ensure that your supply chain is operating at peak performance.
          </p>
        </div>
        <div className="e-warehouse-card">
          <FontAwesomeIcon icon={faLock} className="e-warehouse-icon" />
          <p className="e-warehouse-text">
            Security is also a top priority, with role-based access control ensuring that sensitive data is only 
            accessible to authorized personnel. Our cloud-based architecture guarantees uptime and performance, while 
            ensuring that your data is always backed up and protected.
          </p>
        </div>
        <div className="e-warehouse-card">
          <FontAwesomeIcon icon={faClipboardCheck} className="e-warehouse-icon" />
          <p className="e-warehouse-text">
            <strong>Tally Integration:</strong> E-warehouse seamlessly integrates with Tally, one of the leading accounting 
            software platforms, enabling real-time synchronization between your warehouse operations and your financial 
            records. With this integration, you can automatically update your inventory data, sales orders, and 
            purchase records in Tally, reducing the need for manual data entry and ensuring that your accounting system 
            always reflects the latest warehouse activity. This helps improve financial accuracy, streamline bookkeeping, 
            and provide a unified view of your business operations.
          </p>
        </div>
        
      </div>
      
      {/* How to Use Section */}
      <div className="e-warehouse-how-to">
        <button className="e-warehouse-toggle-btn" onClick={toggleInstructions}>
          <FontAwesomeIcon icon={faQuestionCircle} className="e-warehouse-toggle-icon" />
          How to Use E-Warehouse
        </button>
        {showInstructions && (
          <div className="e-warehouse-instructions">
            <ol>
              <li>
                <strong>Create an Account:</strong> Sign up for an E-Warehouse account through our platform to access all features.
              </li>
              <li>
                <strong>Set Up Your Warehouse:</strong> Add warehouse locations, define inventory items, and configure automation settings.
              </li>
              <li>
                <strong>Integrate with ERP:</strong> Connect E-Warehouse with your existing ERP systems for real-time data synchronization.
              </li>
              <li>
                <strong>Track Inventory:</strong> Use the dashboard to monitor stock levels, replenish inventory, and manage orders.
              </li>
              <li>
                <strong>Analyze Data:</strong> Utilize analytics and reports to optimize warehouse operations and improve decision-making.
              </li>
              <li>
                <strong>Secure Your Data:</strong> Configure role-based access control and ensure data security with cloud backups.
              </li>
            </ol>
          </div>
        )}
      </div>
     
      <video
  src={require("./1204.mp4")}
  alt="E-Warehouse platform"
  className="e-warehouse-video"
  autoPlay
  loop
  playsInline  // Ensure inline playback on mobile
  controls     // Adds playback controls including volume
></video>



      {/* Free Trial Section */}
      <div className="e-warehouse-trial">
        <h4>Start Your 7-Day Free Trial</h4>
        <p>Experience all the features of E-warehouse for free! Get started today and streamline your warehouse management.</p>
        <button className="e-warehouse-btn"    onClick={handleNavigateToContact}  >Start Free Trial</button>
      </div>

      {/* Get Offers Section */}
      <div className="e-warehouse-offers">
        <h4>Get Exclusive Offers</h4>
        <p>Contact now and unlock special deals and discounts on our premium plans. Don’t miss out!</p>
        <button className="e-warehouse-btn"  onClick={handleNavigateToContact}  
        
        >Get Offers</button>
      </div>

      {/* Contact Us Section */}
      <div className="e-warehouse-contact">
        <h4>Contact Us</h4>
        <p>If you have any questions or need support, feel free to reach out to us!</p>
        <div className="e-warehouse-contact-info">
          <p><FontAwesomeIcon icon={faPhoneAlt} /> <strong>Phone:</strong> +91 95006 25633</p>
          <p><FontAwesomeIcon icon={faEnvelope} /> <strong>Email:</strong> development@imatrixtechnologies.com</p>
        </div>
        <button className="e-warehouse-btn"    onClick={handleNavigateToContact}    >Contact Support</button>
      </div>
    </div>
  );
};

export default EWarehouse;
