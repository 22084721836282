import React, { useRef, useState } from 'react';
import { FaCheckCircle, FaMapMarkedAlt, FaEnvelope, FaPhone, FaWhatsapp } from 'react-icons/fa';
import './ContactUs.css';

const Content_New = () => {
  const form = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    mobileNumber: '',
    email: '',
    product: '',
    message: '',
  });



  const handleSubmit = async (event) => {
    event.preventDefault();

    // Prepare the data from formData state
    const dataToSend = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      companyName: formData.companyName,
      mobileNumber: formData.mobileNumber,
      email: formData.email,
      product: formData.product,
      message: formData.message,
      receiverEmail: 'kiruthvikg@gmail.com', // Add the receiver's email or dynamic value here
    };


    try {
      const response = await fetch('http://192.168.1.148:5001/send-email_imatrix', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      const data = await response.json(); // Parse the JSON response
      if (response.ok) {
        console.log(data.message); // Handle success
        setIsModalOpen(true); // Show the success modal

        // Reset form data after successful submission
        setFormData({
          firstName: '',
          lastName: '',
          companyName: '',
          mobileNumber: '',
          email: '',
          product: '',
          message: '',
        });
      } else {
        console.error('Error:', data.message); // Handle error
      }
    } catch (error) {
      console.error('Failed to fetch:', error); // Handle network or other errors
    }
  };

  return (
    <section className="contacts padding">
      <div className="container shadow flexSB">
        {/* Left Section */}
        <div className="left row">
          <h1>Contact Information</h1>
          <div className="contact-info">
            <div className="box">
              <h4 style={{ display: 'flex', alignItems: 'center' }}>
                <a
                  href="https://www.google.com/maps/place/iMatrix+Technologies+Pvt+Ltd/@13.0687222,80.2138012,17z/data=!3m1!4b1!4m6!3m5!1s0x3a5266972fffffff:0x92791e68a0950605!8m2!3d13.0687222!4d80.2163761!16s%2Fg%2F1tdqfx71?entry=ttu&g_ep=EgoyMDI0MTAxNi4wIKXMDSoASAFQAw%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="map-link"
                >
                  <FaMapMarkedAlt size={50} />
                  <span>Click Here</span>
                </a>
              </h4>
              <p className="address">
                <h1>Address</h1>
                <br />
                iMatrix Technologies Pvt Ltd <br />
                #R-76 M M D A Colony, Arumbakkam, <br />
                Chennai - 600 106
              </p>
            </div>
            <div className="contact-info-container">
              <div className="box">
                <h4><FaEnvelope /> Email</h4>
                <div className="contact-details">
                  <div className="contact-item">
                    <strong><FaEnvelope /> CUSTOMIZATION:</strong>
                    <p>
                      tdl@imatrixtechnologies.com <br />
                      <FaPhone /> +91 9841594294 , +91 9841542801
                    </p>
                  </div>
                  <div className="contact-item">
                    <strong><FaEnvelope /> SUPPORT:</strong>
                    <p>
                      support@imatrixtechnologies.com <br />
                      <FaPhone /> +91 9841609990, +91 9841564455
                    </p>
                  </div>
                  <div className="contact-item">
                    <strong><FaEnvelope /> MARKETING:</strong>
                    <p>
                      marketing@imatrixtechnologies.com <br />
                      <FaPhone /> +91 9841609963, +91 9962000567
                    </p>
                  </div>
                  <div className="contact-item">
                    <strong><FaEnvelope /> HARDWARE:</strong>
                    <p>
                      hardware@imatrixtechnologies.com <br />
                      <FaPhone /> +91 9841545557
                    </p>
                  </div>
                  <div className="contact-item">
                    <strong><FaEnvelope /> SOFTWARE:</strong>
                    <p>
                      development@imatrixtechnologies.com <br />
                      <FaPhone /> +91 9500625633
                    </p>
                  </div>
                  <div className="contact-item">
                    <strong><FaWhatsapp /> WhatsApp:</strong>
                    <p> +91 9841609990 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
 {/* Right Section */}
        <div className="right row">
          <h1>Get In Touch</h1>
          <form ref={form} className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                required
                value={formData.firstName}
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                required
                value={formData.lastName}
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
              />
            </div>
            <input
              style={{ width: '94%' }}
              type="text"
              name="companyName"
              placeholder="Company Name"
              value={formData.companyName}
              onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
            />
            <input
  style={{ width: '94%' }}
  type="text"
  name="mobileNumber"
  placeholder="Mobile Number"
  value={formData.mobileNumber}
  onChange={(e) => {
    const value = e.target.value;

    // Ensure only numbers are allowed and limit the input to 10 characters 

    
    if (/^\d{0,10}$/.test(value)) {
      setFormData({ ...formData, mobileNumber: value });
    }
  }}
  maxLength="10"
  pattern="[0-9]{10}"
/>
            <input
              style={{ width: '94%' }}
              type="email"
              name="email"
              placeholder="Email Address"
              required
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            <div style={{ width: '94%' }}>
              <select
                name="product"
                required
                style={{
                  width: '108%',
                  height: '55px',
                  marginBottom: '10px',
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  fontSize: '16px',
                }}
                value={formData.product}
                onChange={(e) => setFormData({ ...formData, product: e.target.value })}
              >
                <option value="" disabled>Select a Product</option>
                <option value="Tallyprime Gold">Tallyprime Gold</option>
                <option value="Tallyprime Sliver">Tallyprime Sliver</option>
                <option value="Tallyprime Server">Tallyprime Server</option>
                <option value="Tally AWS">Tally AWS</option>
                <option value="Corporate Training">Corporate Training</option>
                <option value="Tally Implementation">Tally Implementation</option>
                <option value="Tally Customization">Tally Customization</option>
                <option value="Biz Analyst">Biz Analyst</option>
                <option value="Mobile Apps">Mobile Apps</option>
                <option value="Web Application">Web Application</option>
                <option value="Biometrics">Biometrics</option>
                <option value="Hardware">Hardware</option>
                <option value="CCTV">CCTV</option>
              </select>
            </div>
            <textarea
              name="message"
              placeholder="Your Message"
              required
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            ></textarea>
            <button type="submit" className="primary-btn">Send Message</button>
          </form>
        </div>
      </div>
      {/* Modal Popup for Success Message */}     
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2><FaCheckCircle style={{ color: 'green', marginRight: '8px' }} />Thank you!</h2>
            <p>Our representative will contact you within 24 hours on working days.</p>
            <button onClick={() => setIsModalOpen(false)}>Close</button>
          </div>
        </div>
      )}

      {/* Google Maps Section */}
      <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.2481050359854!2d80.21176271425258!3d13.06872741341825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266972fffffff%3A0x92791e68a0950605!2siMatrix%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1695298143896!5m2!1sen!2sin"
            width="600"
            height="500px"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
    </section>
  );
};

export default Content_New;
