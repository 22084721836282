import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';  // Import WhatsApp icon
import './HeroSection.css';

const HeroSection = ({ scrollToTimeline }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showChat, setShowChat] = useState(false); // State to control chat visibility
  const [chatMessage, setChatMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [step, setStep] = useState(0); // Track the step of the conversation

  const navigate = useNavigate();

  const goToAboutPage = () => {
    navigate('/AboutPage');
  };

  const goToWebDevelopmentPage = () => {
    navigate('/BIO');
  };

  const goToMobileDeveloper = () => {
    navigate('/MobileDeveloper');
  };

  const goTotallyprime = () => {
    navigate('/tallyprime');
  };

  const goToHardwareServices = () => {
    navigate('/HardwareServices');
  };

  const goToSecuritySystem = () => {
    navigate('/SecuritySystem');
  };

  const goToServerPage= () => {
    navigate('/ServerPage');
  };
  
  const goToAntivirus= () => {
    navigate('/Antivirus');
  };

  const goToAutoBackupSoftware= () => {
    navigate('/webcat');
  };
  const goToBiss= () => {
    navigate('/BizAnalyst');
  };


  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Toggle chat box visibility and start the conversation with the first question
  const toggleChat = () => {
    setShowChat(!showChat);
    if (!showChat) {
      setMessages(['Hello! Welcome to our live chat. What is your name?']);
      setStep(1); // Start the first step of the conversation
    }
  };

  // Handle the chat flow based on the current step
  const handleSendMessage = (e) => {
    e.preventDefault();
    if (chatMessage.trim() === '') return; // Prevent sending empty messages

    const updatedMessages = [...messages, `User: ${chatMessage}`];

    if (step === 1) {
      updatedMessages.push('Thank you! Can you please provide your contact details?');
      setStep(2);
    } else if (step === 2) {
      updatedMessages.push('Great! Could you describe the issue you are facing?');
      setStep(3);
    } else if (step === 3) {
      updatedMessages.push('Thank you! Can you provide more details about the problem?');
      setStep(4);
    } else if (step === 4) {
      updatedMessages.push('Thank you for your time! We will contact you soon.');
      setStep(5);

      // Close the chat after a brief delay
      setTimeout(() => {
        setShowChat(false);
        setStep(0);
        setMessages([]); // Clear chat after closing
      }, 10000); // Close after 3 seconds
    }

    setMessages(updatedMessages);
    setChatMessage(''); // Clear the input field
  };

  // Function to handle WhatsApp icon click
  const handleWhatsAppClick = () => {
    setShowChat(false); // Close chat when WhatsApp is clicked
    window.open("https://wa.me/9841609990", "_blank"); // Replace with your WhatsApp number
  };

  return (
    <div className="hero-container">
 
      {!showMenu ? (
        <div className="hero-content" style={{marginTop:'50px' ,}}>
          <div className="left-content">
            <div className="right-menu">
              <div className="card-lists">
                <div className="cardviews" onClick={goTotallyprime}>
                  <img src="/images/Primelogo.webp" alt="Tally" />
                  <p>Tally</p>
                </div>
                <div className="cardviews" onClick={goToMobileDeveloper}>
                  <img src="/images/istock-131404860final.gif" alt="Mobile Development" />
                  <p>Apps</p>
                </div>
                <div className="cardviews" onClick={goToWebDevelopmentPage}>
                  <img src="/images/GeotimeMar18_2_finger_web_4ff55f3b-97eb-4f7a-b4a8-a98c7f136383_1350x-removebg-preview (1).png" style={{ height:'70%',width:'135px', position:'relative' , top:'-08px'}} alt="Web Development" />
                  <p style={{position:'relative' , top:"-16px"}}>Biometrics</p>
                </div>
                <div className="cardviews" onClick={goToHardwareServices}>
                <img src="/images/71QtoB1MJIL-removebg-preview.png" alt="Hardware" />
                  <p>Hardware</p>
                </div>
                <div className="cardviews" onClick={goToSecuritySystem}>
                  <img src="/images/shopping.webp" alt="CCTV" />
                  <p>CCTV</p>
                </div>
                <div className="cardviews" onClick={goToServerPage}>
                  <img src="/images/servers.jpg" alt="Server Management" />
                  <p>Server</p>
                </div>
                <div className="cardviews" onClick={goToAntivirus}>
                  <img src="/images/WhatsApp_Image_2024-10-21_at_4.47.15_PM-removebg-preview.png" alt="Antivirus" />
                  <p>Antivirus</p>
                </div>
                <div className="cardviews" onClick={goToAutoBackupSoftware}>
                  <img src="/images/what-is-application-software2-removebg-preview.png" alt="Server Management" />
                  <p>Software</p>
                </div>
                <div className="cardviews" onClick={goToBiss}>
                  <img src="/images/logotally/unnamed.png" alt="Server Management" />
                  <p>Biz Analyst</p>
                </div>
              </div>
            </div>



         
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '130%',
        background: 'radial-gradient(circle at top left,  rgba(255,250,230,255), rgb(243 206 156)', // Light to dark gradient
        clipPath: 'ellipse(130% 50% at 50% 0%)',
        zIndex: -1
      }}>
      </div>


            <div style={{justifyContent:'center', width:'158%'}}>
              <p style={{width:'80%', justifyContent:'center', textAlign:'start', marginLeft:'10%'}}>
                <button className="cta-button" onClick={goToAboutPage}>
                  <strong style={{ fontWeight: 'bold' }}>iMatrix Technologies Pvt Ltd</strong>
                </button>
                <p style={{ textAlign: 'justify' }}>
                  <strong style={{ fontWeight: 'bold' }}>iMatrix Technologies</strong> began its humble journey in 2006, with a clear vision to deliver innovative technical solutions and exceptional customer service. Over the years, we have developed fine-tuned, successful strategies for our vendors, leveraging solid expertise to push the boundaries of service delivery. Our commitment to excellence has helped us redefine the customer experience, taking it to new dimensions.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  In 2013, <strong style={{ fontWeight: 'bold' }}>iMatrix Technologies</strong> was formally incorporated as <strong style={{ fontWeight: 'bold' }}>iMatrix Technologies Private Limited</strong>, further cementing our position in the industry. Our dedication to serving customers from all sectors is driven by our belief in the power of technology to improve lives. We pride ourselves on meeting timelines and ensuring lasting customer satisfaction through the appropriate use of our products and services.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  At <strong style={{ fontWeight: 'bold' }}>iMatrix Technologies</strong>, we synchronize our strengths to deliver exceptional results for our clients. Our unwavering commitment to continuous improvement ensures that we go beyond expectations, consistently upgrading our competencies to provide cutting-edge solutions. With an unrelenting zeal to serve, we are always ready to meet the ever-evolving needs of our clients.
                </p>
              </p>
            </div>
            {/* <div className="illustration" style={{marginTop:'200px'}}>
              <img src="/images/image0_0 (2).jpg" alt="Illustration" className="illustration-img" />
            </div> */}
          </div>
        </div>
      ) : (
        <div className="hero-content">
          <div className="main-content">
            <h3>About iMatrix Technologies</h3>
            <p>
              We provide cutting-edge technology solutions tailored to your business needs. 
              Our team is dedicated to ensuring your success through innovation and customer-centric services.
            </p>
            <button className="cta-button" onClick={toggleMenu}>
              BACK TO HOME
            </button>
          </div>
        </div>
      )}

      {/* Chat Box */}
      <div className={`chat-box ${showChat ? 'show' : ''}`}>
        <div className="chat-header">Live Chat</div>
        <div className="chat-body">
          {messages.length > 0 ? (
            messages.map((message, index) => <p key={index}>{message}</p>)
          ) : (
            <p>No messages yet...</p>
          )}
        </div>
        <div className="chat-input">
          <input
            type="text"
            placeholder="Type a message..."
            value={chatMessage}
            onChange={(e) => setChatMessage(e.target.value)}
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>

      {/* Chat Toggle Button */}
      <div className="chat-toggle" onClick={toggleChat}>
        {showChat ? 'X' : '💬'}
      </div>

      {/* WhatsApp Icon (conditionally hidden) */}
      {!showChat && (
        <a
          onClick={handleWhatsAppClick}
          className="whatsapp-icon"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp size={50} color="#25D366" />
        </a>
      )}
    </div>
  );
};

export default HeroSection;
